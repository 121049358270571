import { configureStore } from '@reduxjs/toolkit';
import { appReducer } from './slices/app.slice';
import { gameReducer } from './slices/game.slice';

export const store = configureStore({
  reducer: {
    app: appReducer,
    game: gameReducer,
  },
});
