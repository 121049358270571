import { createSlice } from '@reduxjs/toolkit';

export const initialState = {};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    // setPhoneNumber(state, action) {
    //   state.phoneNumber = action.payload;
    // },
  },
  extraReducers: {},
});
// export const {  } = appSlice.actions;
export const appReducer = appSlice.reducer;
