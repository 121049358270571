export function currencyFormat(n) {
  return (
    <span>
      {/* {Math.floor(n)
        ?.toFixed(0)
        ?.replace(/./g, function (c, i, a) {
          return i > 0 && c !== '.' && (a.length - i) % 3 === 0 ? ' ' + c : c;
        })} */}
      {n}

      {/* <span style={{ fontSize: '12px', opacity: 0.7 }}>.{23}</span> */}
      <span> ₽</span>
    </span>
  );
}
