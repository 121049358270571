import { createSlice } from '@reduxjs/toolkit';

import { initStateCheckSteam, reducerCheckSteam } from '../actions/game/checkSteam';
import { initStateGetSetting, reducerGetSetting } from '../actions/game/getSetting';
import { initStateCreatePayment, reducerCreatePayment } from '../actions/game/createPayment';
import { initStateGetTransaction, reducerGetTransaction } from '../actions/game/getTransaction';

export const initialState = {
  ...initStateCheckSteam,
  ...initStateGetSetting,
  ...initStateCreatePayment,
  ...initStateGetTransaction,
};

export const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    resetCheckSteam(state, action) {
      state.checkSteam = initStateCheckSteam.checkSteam;
    },
    resetCreatePayment(state, action) {
      state.createPayment = initStateCreatePayment.createPayment;
    },
    resetGetTransaction(state, action) {
      state.getTransaction = initStateGetTransaction.getTransaction;
    },
  },
  extraReducers: {
    ...reducerCheckSteam,
    ...reducerGetSetting,
    ...reducerCreatePayment,
    ...reducerGetTransaction,
  },
});
export const { resetCheckSteam, resetCreatePayment, resetGetTransaction } = gameSlice.actions;
export const gameReducer = gameSlice.reducer;
