import React, { useState } from 'react';
import styles from './FAQItem.module.scss';
import clsx from 'clsx';
import AnimateHeight from 'react-animate-height';
const FAQItem = ({ title, text }) => {
  const [height, setHeight] = useState(0);
  return (
    <>
      <div className={clsx(styles.wrap)}>
        <div className={clsx(styles.head)} onClick={() => setHeight(height === 0 ? 'auto' : 0)}>
          <div className={clsx(styles.title)}>{title}</div>
          <div className={clsx(styles.icon, height !== 0 && styles.iconActive)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
              <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m6 9l6 6l6-6" />
            </svg>
          </div>
        </div>
        <AnimateHeight
          id="example-panel"
          duration={500}
          height={height} // see props documentation below
        >
          <div className={clsx(styles.content)}>{text}</div>
        </AnimateHeight>
      </div>
    </>
  );
};

export default FAQItem;
