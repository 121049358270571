import React, { useEffect, useState } from 'react';
import styles from './Donate.module.scss';
import clsx from 'clsx';
import { NumericFormat } from 'react-number-format';
import { Controller, useForm } from 'react-hook-form';
import { currencyFormat } from '../../../utils/currencyFormat';
import { useDispatch, useSelector } from 'react-redux';
import AnimateHeight from 'react-animate-height';
import { checkSteam } from '../../../redux/actions/game/checkSteam';
import { resetCheckSteam, resetCreatePayment } from '../../../redux/slices/game.slice';
import { calcReversePercent } from '../../../utils/calcReversePercent';
import { createPayment } from '../../../redux/actions/game/createPayment';
const Donate = () => {
  const [activePayment, setActivePayment] = useState('sbp');
  const [showWhere, setShowWhere] = useState(false);
  const [height, setHeight] = useState(0);
  const form = useForm();
  const dispatch = useDispatch();
  const errors = form.formState.errors;
  console.log(errors);
  const payments = [
    // {
    //   image: '/img/crypto.png',
    //   slug: 'crypto',
    // },
    {
      image: '/img/sbp.svg',
      slug: 'sbp',
    },
    // {
    //   image: '/img/ru-cards-sbp.png',
    //   slug: 'ru-cards-sbp',
    // },
  ];

  const [steamLogin, setSteamLogin] = useState();
  const {
    checkSteam: { data: checkSteamData, error: checkSteamError, loading: checkSteamLoading },
    getSetting: { data: settingData },
    createPayment: { data: createPaymentData, loading: createPaymentLoading },
  } = useSelector((state) => state.game);
  useEffect(() => {
    if (steamLogin) {
      const search = setTimeout(() => {
        dispatch(checkSteam(steamLogin));
      }, 500);
      return () => clearTimeout(search);
    } else {
      dispatch(resetCheckSteam());
    }
  }, [steamLogin]);

  React.useEffect(() => {
    const subscription = form.watch((value, { name, type }) => {
      if (name == 'steamLogin') {
        setSteamLogin(value.steamLogin);
      }
    });
    return () => subscription.unsubscribe();
  }, [form.watch]);
  const sumWatch = form.watch('sum');
  const emailWatch = form.watch('email');
  const sumParse = typeof sumWatch == 'string' ? parseInt(sumWatch?.replaceAll(' ', '')) : sumWatch;
  const sumTotal = calcReversePercent(sumParse, settingData?.percent);
  const onSubmit = (data) => {
    if (checkSteamData?.status === 'success') {
      console.log(data);
      dispatch(createPayment({ sumTotal, ...data, sum: sumParse, checkSteamTransactionId: checkSteamData.transactionId }));
    }
  };
  useEffect(() => {
    if (createPaymentData) {
      window.location.href = createPaymentData?.paymentUrl;
      dispatch(resetCreatePayment());
    }
  }, [createPaymentData]);
  function randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }
  const [robotNumber, setRobotNumber] = useState(randomIntFromInterval(1, 6));

  return (
    <>
      {createPaymentLoading && (
        <div className={clsx(styles.overlay)}>
          <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}>
            <span className={clsx(styles.loader)}></span>
          </div>
        </div>
      )}
      <div className={clsx(styles.headWrap)}>
        {' '}
        <div className={clsx(styles.head)}>
          {' '}
          <div className={clsx(styles.robotWrap)}>
            <img src={`/img/robots-${robotNumber}.png`} alt="" className={clsx(styles.robot)} />
          </div>
          <div className={clsx(styles.headBottom)}>
            {' '}
            <div className={clsx(styles.headTitle)}>Пополнение Steam аккаунта</div>
            <div className={clsx(styles.headDesc)}>Моментальное пополнение, низкая комиссия, СНГ</div>
          </div>
        </div>
      </div>

      <div className={clsx(styles.wrap)}>
        {/* <div className={clsx(styles.title)}>Пополнение Steam</div> */}
        <div className={clsx(styles.inputBox)}>
          <input
            style={{ ...((checkSteamData || checkSteamError || checkSteamLoading) && { paddingBottom: '32px' }) }}
            type="text"
            className={clsx(styles.input, (errors?.['steamLogin'] || checkSteamError || checkSteamData?.status === 'error') && styles.inputError)}
            placeholder={'Логин Steam'}
            autoComplete="off"
            {...form.register('steamLogin', { required: true })}
          />
          <div className={clsx(styles.label, steamLogin && styles.labelShow)}>Логин Steam</div>
          <div className={clsx(styles.inputBoxRight)} onClick={() => setHeight(height === 0 ? 'auto' : 0)}>
            Где найти ?
          </div>
          <AnimateHeight id="image" duration={500} height={height} style={{ width: '100%', gridColumn: '1/3' }}>
            {' '}
            <img src="/img/login-help.jpg" alt="" className={clsx(styles.whereImage)} />
          </AnimateHeight>
          {checkSteamLoading ? (
            <span class="loader"></span>
          ) : checkSteamData?.status === 'success' ? (
            <div className={clsx(styles.checkStatus, styles.checkSuccess)}>
              <div className={clsx(styles.checkIcon)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="14px" height="14px" viewBox="0 0 24 24">
                  <path fill="currentColor" d="m10.6 16.6l7.05-7.05l-1.4-1.4l-5.65 5.65l-2.85-2.85l-1.4 1.4zM12 22q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22" />
                </svg>
              </div>
              <div className={clsx(styles.checkText)}>Аккаунт Steam найден</div>
            </div>
          ) : checkSteamError || checkSteamData?.status === 'error' ? (
            <div className={clsx(styles.checkStatus, styles.checkError)}>
              {' '}
              <div className={clsx(styles.checkIcon)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="14px" height="14px" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M12 2c5.53 0 10 4.47 10 10s-4.47 10-10 10S2 17.53 2 12S6.47 2 12 2m3.59 5L12 10.59L8.41 7L7 8.41L10.59 12L7 15.59L8.41 17L12 13.41L15.59 17L17 15.59L13.41 12L17 8.41z" />
                </svg>
              </div>
              <div className={clsx(styles.checkText)}>Steam не найден</div>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className={clsx(styles.divider)}></div>
        <div className={clsx(styles.inputBox)}>
          <Controller
            rules={{ required: true }}
            control={form.control}
            name="sum"
            render={({ field: { onChange, name, value } }) => (
              <NumericFormat
                style={{ fontSize: '22px' }}
                onBlur={(e, r) => {
                  const val = parseInt(e.target.value?.replaceAll(' ', ''));

                  if (val > 250000) {
                    form.setValue('sum', 250000);
                  } else if (val < 10) {
                    form.setValue('sum', 10);
                  }
                }}
                className={clsx(styles.input, errors?.['sum'] && styles.inputError)}
                placeholder={'Сумма пополнения'}
                suffix=" ₽"
                thousandSeparator=" "
                name={name}
                value={value}
                onChange={onChange}
                autoComplete="off"
              />
            )}
          />{' '}
          <div className={clsx(styles.label, sumWatch && styles.labelShow)}>Сумма пополнения</div>
          <div className={clsx(styles.inputBoxRight)}></div>
        </div>
        <div className={clsx(styles.divider)}></div>
        <div className={clsx(styles.inputBox)}>
          <input
            type="text"
            className={clsx(styles.input, errors?.['email'] && styles.inputError)}
            placeholder={'Ваш email'}
            autoComplete="off"
            {...form.register('email', {
              required: false,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'invalid email address',
              },
            })}
          />
          <div className={clsx(styles.info)}>Если хотите получить чек и уведомление о зачислении</div>
          <div className={clsx(styles.label, emailWatch && styles.labelShow)}>Ваш email</div>
          <div className={clsx(styles.inputBoxRight)}></div>
        </div>{' '}
        <div className={clsx(styles.divider)}></div>
        <div className={clsx(styles.total)} style={{ marginTop: '25px' }}>
          Сумма пополнения: <span>{sumParse ? currencyFormat(sumParse) : '-'}</span>
        </div>
        <div className={clsx(styles.total)}>
          Комиссия: <span>{sumTotal - sumParse ? currencyFormat(sumTotal - sumParse) : '-'}</span>
        </div>
        <div className={clsx(styles.total)}>
          Сумма к оплате: <span>{sumTotal ? currencyFormat(sumTotal) : '-'}</span>
        </div>
        {/* <div className={clsx(styles.payments)}>
          {payments?.map((pay) => (
            <div
              className={clsx(styles.payment, activePayment == pay.slug && styles.paymentActive)}
              onClick={() => {
                setActivePayment(pay.slug);
              }}>
              <img src={pay.image} alt="" />
            </div>
          ))}
        </div> */}
        <button className={clsx(styles.button)} onClick={form.handleSubmit(onSubmit)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 24 24">
            <path fill="#b4c491" d="M21 18v1a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v1h-9a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2m0-2h10V8H12m4 5.5a1.5 1.5 0 0 1-1.5-1.5a1.5 1.5 0 0 1 1.5-1.5a1.5 1.5 0 0 1 1.5 1.5a1.5 1.5 0 0 1-1.5 1.5" />
          </svg>
          Оплатить {sumTotal ? currencyFormat(sumTotal) : ''}
        </button>
      </div>

      <div className={clsx(styles.wrap)} style={{ paddingTop: '20px' }}>
        {' '}
        <div className={clsx(styles.support)}>
          <div className={clsx(styles.supportTitle)}>Поддержка</div>
          <div className={clsx(styles.supportLinks)}>
            <a href="https://t.me/DonateGold" className={clsx(styles.supportLink)}>
              {' '}
              <div className={clsx(styles.supportIcon)}></div>
              @donategold
            </a>
            &nbsp;/&nbsp;
            <a href="mailto:support@donate-gold.ru" className={clsx(styles.supportLink)}>
              {' '}
              <div className={clsx(styles.supportIcon, styles.supportEmail)}></div>
              support@donate-gold.ru
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Donate;
