import React, { useEffect } from 'react';
import styles from './Success.module.scss';
import clsx from 'clsx';
import { currencyFormat } from '../../../utils/currencyFormat';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getTransaction } from '../../../redux/actions/game/getTransaction';
import { resetGetTransaction } from '../../../redux/slices/game.slice';
const Success = () => {
  const navigate = useNavigate();
  const { transactionId } = useParams();
  const dispatch = useDispatch();
  const {
    getTransaction: { data: transactionData, loading: transactionLoading },
  } = useSelector((state) => state.game);
  useEffect(() => {
    if (transactionId) {
      dispatch(getTransaction(transactionId));
    }
  }, [transactionId]);
  useEffect(() => {
    if (transactionData?.status == 'Not found') {
      navigate('/');
      dispatch(resetGetTransaction());
    }
  }, [transactionData, transactionLoading]);

  return (
    <>
      {transactionData && (
        <div className={clsx(styles.wrap)}>
          <div className={clsx(styles.icon)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24">
              <path fill="#18c761" d="m10.6 16.6l7.05-7.05l-1.4-1.4l-5.65 5.65l-2.85-2.85l-1.4 1.4zM12 22q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22" />
            </svg>
          </div>
          <div className={clsx(styles.title)}>Пополение отправлено</div>
          <div className={clsx(styles.sum)}>{currencyFormat(transactionData?.donateSum)}</div>
          <div className={clsx(styles.steam)}>{transactionData?.steam}</div>
          <div className={clsx(styles.text)}>На ваш email поступит уведомление после зачисления средств на Steam</div>
          <button
            className={clsx(styles.button)}
            onClick={() => {
              navigate('/');
            }}>
            Закрыть
          </button>
          <div className={clsx(styles.divider)}></div>
          <div className={clsx(styles.supportTitle)}>Поддержка</div>
          <a href="https://t.me/DonateGold" className={clsx(styles.supportTelegram)}>
            @donategold
          </a>
          <a href="mailto:support@donate-gold.ru" className={clsx(styles.supportEmail)}>
            support@donate-gold.ru
          </a>
        </div>
      )}
    </>
  );
};

export default Success;
