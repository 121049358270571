import { useRoutes } from 'react-router';
import HomePage from './pages/site/HomePage/HomePage';
import './styles.scss';
import SiteLayout from './components/common/SiteLayout/SiteLayout';
import SuccessPage from './pages/site/SuccessPage/SuccessPage';

function App() {
  let routes = useRoutes([
    {
      path: '/',
      element: <HomePage />,
    },
    {
      path: '/success/:transactionId',
      element: <SuccessPage />,
    },
  ]);

  return <SiteLayout>{routes}</SiteLayout>;
}

export default App;
