import React from 'react';
import styles from './FAQ.module.scss';
import clsx from 'clsx';
import FAQItem from '../FAQItem/FAQItem';
const FAQ = () => {
  const data = [
    {
      title: 'Где взять логин Steam? ',
      text: (
        <p>
          Логин Steam вы вводите при авторизации, когда заходите на свой Steam аккаунт. Ещё свой логин можно найти тут{' '}
          <a style={{ display: 'inline' }} href="https://store.steampowered.com/account">
            https://store.steampowered.com/account
          </a>
          , не путайте с никнеймом аккаунта.
        </p>
      ),
    },
    {
      title: 'Аккаунт каких стран можно пополнить?',
      text: 'Россия, Беларусь, Казахстан, Киргизия (Кыргызстан), Армения, Таджикистан, Узбекистан, Азербайджан, Молдова, Украина',
    },
    {
      title: 'Как пополнить аккаунт Крыма, ДНР/ЛНР?',
      text: (
        <>
          Главное что нужно сделать - это показать Steam'у, что вы заходите через российский IP. Для этого выполните строго все действия: <br /> 1. Завершите все сессии Steam, выйдя из аккаунта со всех устройств. <br />
          2. Переведите свой мобильный телефон в авиа режим, на котором установлен аутентификатор Steam. <br />
          3. С помощью VPN с российским IP (лучше Москва или Санкт-Петербург) войдите в аккаунт через браузер Steam (мобильный аутентификатор в авиа режиме все равно предоставит вам код для входа). <br />
          4. После успешной авторизации подождите 10-15 минут и сделайте пополнение через наш сервис. <br />
          <br />
          Если все сделано правильно, пополнение будет успешным.
        </>
      ),
    },
    {
      title: 'Допустили ошибку в аккаунте?',
      text: 'Если вы отправили пополнение на существующий чужой аккаунт, к сожалению, возврат денег невозможен. Попробуйте написать владельцу аккаунта сами и договориться.',
    },
    {
      title: 'Время зачисления средств на аккаунт?',
      text: 'Время зачисления средств на аккаунт Steam составляет одну-две минуты, в редких случаях до трех часов. Если зачисления нет - напишите нам.',
    },
    {
      title: 'На аккаунт пришла не та сумма?',
      text: 'Так как Steam работает по своему внутреннему курсу, и он колеблется, то окончательная сумма поступления может плавать на 1-2%. Поэтому отправляйте сумму всегда чуть большую, чем вам нужно.',
    },
    {
      title: 'Как пополнить новый Steam аккаунт?',
      text: 'Если на вашем Steam аккаунте ещё не было пополнений кошелька, и вы не поиграли в игры, то при первом пополнении у вас автоматически выставится регион, отличный от РФ. Так как пополнение от нас приходит в $. Чтобы выставить корректно регион, скачайте пару бесплатных игр и поиграйте немного в них без включенного VPN, чтобы ваш родной IP увидела система. И тогда Steam корректно выставит ваш регион и вашу валюту. И только после этого можете делать пополнение.',
    },
  ];
  return (
    <>
      <div className={clsx(styles.wrap)}>
        <div className={clsx(styles.title)}>Вопросы и Ответы</div>
        <div className={clsx(styles.list)}>
          {data?.map((item) => (
            <FAQItem {...item} />
          ))}
        </div>
      </div>
    </>
  );
};

export default FAQ;
