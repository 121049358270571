export const calcReversePercent = (sum, percent) => {
  if (!sum) {
    return 0;
  }

  const priceWithPercent = addPercentToNumber(sum, percent);

  const reversePercentagePrice = ((priceWithPercent * 100) / (100 - 100 * (10 * 0.01).toFixed(2))).toFixed(0);
  return parseInt(reversePercentagePrice);
};

function addPercentToNumber(price, discount) {
  var numVal1 = parseFloat(price);
  var numVal2 = parseFloat(discount) / 100;

  var totalValue = numVal1 + numVal1 * numVal2;
  return totalValue.toFixed(2);
}
