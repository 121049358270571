import { createAsyncThunk, current } from '@reduxjs/toolkit';
import axios from 'axios';

export const initStateGetTransaction = {
  getTransaction: { data: null, loading: false, error: null },
};

export const getTransaction = createAsyncThunk('tariff/GetTransaction', async (transactionId, { rejectWithValue, fulfillWithValue }) => {
  return await axios
    .get(`${process.env.REACT_APP_SERVER_API}/transaction/${transactionId}`)
    .then((res) => {
      return fulfillWithValue(res.data);
    })
    .catch((res) => {
      return rejectWithValue(res.response.data);
    });
});

export const reducerGetTransaction = {
  [getTransaction.pending]: (state) => {
    state.getTransaction.loading = true;
    state.getTransaction.error = null;
    state.getTransaction.data = null;
  },
  [getTransaction.fulfilled]: (state, action) => {
    state.getTransaction.loading = false;
    state.getTransaction.data = action.payload;
    state.getTransaction.error = null;
  },
  [getTransaction.rejected]: (state, action) => {
    state.getTransaction.loading = false;
    state.getTransaction.error = action.payload;
    state.getTransaction.data = null;
  },
};
