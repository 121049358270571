import React from 'react';
import styles from './Footer.module.scss';
import clsx from 'clsx';
const Footer = () => {
  return (
    <>
      <div className={clsx(styles.wrap)}>
        <div className={clsx(styles.copy)}>2023-2024 © Donate Steam</div>
        <div className={clsx(styles.text)}>
          Проект{' '}
          <a href="https://donate-gold.ru" className={clsx(styles.link)}>
            donate-gold.ru
          </a>
        </div>{' '}
        <a href="https://donate-gold.ru/terms-of-use" className={clsx(styles.link)}>
          Документация
        </a>
      </div>
    </>
  );
};

export default Footer;
