import React, { useEffect } from 'react';
import styles from './SuccessPage.module.scss';
import { Navigate } from 'react-router';
import Donate from '../../../components/common/Donate/Donate';
import FAQ from '../../../components/common/FAQ/FAQ';
import { useDispatch } from 'react-redux';
import { getSetting } from '../../../redux/actions/game/getSetting';
import Success from '../../../components/common/Success/Success';
import Footer from '../../../components/common/Footer/Footer';
const SuccessPage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSetting());
  }, []);

  return (
    <>
      <div className="container">
        <Success />
        <Footer />
      </div>
    </>
  );
};

export default SuccessPage;
